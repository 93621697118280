export enum AppRoutes {
    Home = '',
    Register = 'register',
    Login = 'login',
    ForgotPassword = 'forgot-password',
    Profile = 'profile',
    Videos = 'videos',
    Company = 'company',
    Categories = 'categories',
    Events = 'events',
    News = 'news',
    Search = 'search',
    Slug = ':slug',
    Authors = 'authors',
    Playlists = 'playlists',
    History = 'history',
    DeprecatedLectures = 'lectures',
}
